@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,700&display=swap");

:root {
  --bleujolof: #17b8de;
  --bleujolofhover: #07a8ce;
  --jaunejolof: #fe9d2b;
  --rougejolof: #ed4c14;
  --orangejolof: #f57531;
  /*--fondpage: #F3F6FB;*/
  --fondpage: #d8edf7;
  --gristexte: #606f89;
  /*--gristexte: #343F55;*/
  /*--bleufonce: #404156;*/
  --bleufonce: #343f55;
}

::selection {
  background: var(--bleujolof);
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: var(--bleujolof);
  /* Firefox */
  color: #fff;
  text-shadow: none;
}
::-webkit-selection {
  background: var(--bleujolof);
  /* Safari */
  color: #fff;
  text-shadow: none;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-track {
  background: white;
}
::-webkit-scrollbar-thumb {
  background: var(--bleujolof);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--orangejolof);
}

html {
  overflow-x: hidden;
  height: 100%;
  scrollbar-color: var(--bleujolof) white;
  /*scrollbar-width: thin;*/
}
body {
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 300 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: left !important;
  margin: 0 !important;
  color: var(--gristexte) !important;
  background: url("./../images/bg-header-blue.webp") center no-repeat,
    var(--fondpage) !important;
  background-size: cover !important;
  overflow-x: hidden !important;
  /*height: 100%;*/
}
@media (min-width: 992px) and (max-width: 1700px) {
  body {
    font-size: 17px !important;
    line-height: 20px !important;
  }
}
*,
*:focus,
*:active,
a:link,
a:hover,
a:visited {
  text-decoration: none !important;
  outline: none !important;
  outline-offset: 0 !important;
  color: inherit !important;
}
*:active body {
  color: var(--gristexte) !important;
}
.container {
  max-width: 1600px !important;
  padding: 0 40px !important;
}
@media (min-width: 576px) and (max-width: 1700px) {
  .container {
    padding: 0 80px !important;
  }
}
.container.defilement {
  width: 100% !important;
  max-width: unset !important;
  padding: 0 20px !important;
}
@media (min-width: 1200px) {
  .container.defilement {
    max-width: 1600px !important;
    padding: 0 40px !important;
  }
}
@media (max-width: 991px) {
  .container.resetContainerMobile {
    padding: 0 !important;
  }
}
hr {
  width: 80px !important;
  height: 10px !important;
  border: none !important;
  margin: 0 0 40px 0 !important;
  opacity: 1 !important;
  background: url("./../images/traits.png") !important;
}

/* couleurs */
/*.texte-bleu {
  color: var(--bleujolof) !important;
}
.texte-jaune {
  color: var(--jaunejolof) !important;
}
.texte-rouge {
  color: var(--rougejolof) !important;
}
.texte-orange {
  color: var(--orangejolof) !important;
}
.texte-bleu-clair {
  color: var(--fondpage) !important;
}
.texte-bleu-fonce {
  color: var(--bleufonce) !important;
}
.bg-bleu {
  background: var(--bleujolof) !important;
}
.bg-jaune {
  background: var(--jaunejolof) !important;
}
.bg-rouge {
  background: var(--rougejolof) !important;
}
.bg-orange {
  background: var(--orangejolof) !important;
}
.bg-bleu-clair {
  background: var(--fondpage) !important;
}
.bg-jaune-clair {
  background: rgba(254, 157, 43, 0.1) !important;
}
.bg-bleu-fonce {
  background: var(--bleufonce) !important;
}
.bg-bleu-blanc {
  background: linear-gradient(to bottom, var(--fondpage), white) !important;
}
.bg-bleu-blanc-bleu {
  background: linear-gradient(
    to bottom,
    var(--fondpage),
    white,
    var(--fondpage)
  ) !important;
}
.bg-blanc-bleu {
  background: linear-gradient(to top, var(--fondpage), white) !important;
}
.bg-blanc-jaune {
  background: linear-gradient(to top, rgba(254, 157, 43, 0.1), white), #fff !important;
}
.bg-jaune-blanc {
  background: linear-gradient(to bottom, rgba(254, 157, 43, 0.1), white), #fff !important;
}
.bg-jaune-blanc-bleu {
  background: linear-gradient(
      to bottom,
      rgba(254, 157, 43, 0.1),
      white,
      var(--fondpage)
    ),
    #fff !important;
}*/

/* textes */
/*h1 {
  font-size: 80px;
  line-height: 80px;
  font-weight: 700;
}
h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 300;
  padding-bottom: 10px;
}
h3 {
  font-size: 27px !important;
  line-height: 30px !important;
  font-weight: 300 !important;
  margin-bottom: 16px !important;
}
h1 span {
  font-weight: 300;
  font-size: 70%;
  line-height: 1em;
  display: block;
  margin-top: 10px;
}
header h1,
footer h6,
.imgProjet {
  font-size: 35px;
  color: var(--bleujolof);
  white-space: nowrap;
  font-weight: 700;
}
.titres {
}
.sous-titres {
  max-width: 600px;
  margin: 0 auto;
}
.chapeaux {
  font-size: 27px;
  line-height: 30px;
}
.etapes {
  font-size: 30px;
  font-weight: 700;
  background: white;
  padding: 20px 0;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  border-radius: 75px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0 10px;
}
.listes {
  padding-left: 1px;
}
.listes li {
  list-style: none;
  margin-left: 15px;
}
.listes li::before {
  content: "»";
  color: var(--bleujolof);
  font-size: 24px;
  padding-right: 5px;
  top: 1px;
  position: relative;
  margin-left: -15px;
}
sub,
sup {
  font-size: 66%;
  font-weight: 300;
}*/

/* Divers */
/*hr {
  width: 80px !important;
  height: 10px !important;
  border-image: url("./images/traits.png") 5 / 10px !important;
  margin: 0 0 40px 0 !important;
}
.margin-top-75 {
  margin-top: 75px;
}
.arrondi {
  border-radius: 10px;
}
.arrondi-haut {
  border-radius: 10px 10px 0 0;
}
.pt-50 {
  padding-top: 50px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-200 {
  padding-top: 200px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-200 {
  padding-bottom: 200px;
}*/

/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
